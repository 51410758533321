

  import charge_swap from "./modules/charge_swap.vue"
  import made_for from "./modules/made_for.vue"
  import how_works from "./modules/how_works.vue"
  import unique_recipe from "./modules/unique_recipe.vue"
  import we_offer from "./modules/we_offer.vue"
  import say_si from "./modules/say_si.vue"
  import in_touch from "./modules/in_touch.vue"

  export default {
    name: "Si-landing",
    components: {
      "charge_swap": charge_swap,
      "made_for": made_for,
      "how_works": how_works,
      "unique_recipe": unique_recipe,
      "we_offer": we_offer,
      "say_si": say_si,
      "in_touch": in_touch,
    }
  }
  